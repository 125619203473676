<template>
    <div>
        <Header :seet="number" />
        <el-row type="flex" class="account—settings">
            <el-col :span="3" style="width: 15.5%"></el-col>
            <el-col :span="17" style="z-index: 3;">
                <div class="settings_box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>订单中心</el-breadcrumb-item>
                        <el-breadcrumb-item>{{nametop}}</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div style="display: flex">
                        <div class="box_left">
                            <LeftHurdle />
                        </div>
                        <div class="box_rights">
                            <div class="box_right_top" v-if="authorityJudgment(3)">我的订单</div>
                            <div class="box_right_top" v-else-if="authorityJudgment([2, 4])">审核订单</div>
                            <div class="box_right_top" v-else>所有订单</div>
                            <div class="box_right_cent">
                                <div class="order_top">
                                    <div class="order-class flex-between">
                                        <div class="flex-start">
                                            <div
                                                class="order-cut"
                                                v-for="(
                                                    item, index
                                                ) in listdata"
                                                :key="index"
                                                @click="changeAct(item.value)"
                                            >
                                                <div
                                                    :class="[
                                                        act === item.value
                                                            ? 'order-text'
                                                            : 'order-texts',
                                                    ]"
                                                >
                                                    <div class="order-name">
                                                        {{ item.label }}
                                                        <!-- <div class="order-number">3</div> -->
                                                    </div>
                                                </div>
                                                <div
                                                    :class="[
                                                        act === item.value
                                                            ? 'order-line'
                                                            : '',
                                                    ]"
                                                ></div>
                                            </div>
                                        </div>
                                        <!-- <div
                                            class="order-badch"
                                        >
                                            批量确认收货
                                        </div> -->
                                        <!-- <div
                                            class="order-badch"
                                        >
                                            批量审核
                                        </div> -->
                                    </div>
                                    <div class="flex-start order-timebox" style="width:100%;">
                                        <div class="flex-start order-number" style="width:30%">
                                            <div class="number">订单编号</div>
                                            <el-input
                                                v-model="numberNo"
                                                clearable
                                                @keyup.enter.native="orderquery()"
                                            ></el-input>
                                        </div>
                                        <div class="flex-start order-number" style="width: 48%;">
                                            <div class="numbers">下单时间</div>
                                            <el-date-picker
                                                v-model="value1"
                                                type="datetime"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                placeholder="选择日期时间"
                                                :picker-options="pickerBeginDateBefore"
                                            >
                                            </el-date-picker>
                                            <div class="zhi">至</div>
                                            <el-date-picker
                                                v-model="value2"
                                                type="datetime"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                placeholder="选择日期时间"
                                                :picker-options="pickerBeginDateBefore"
                                            >
                                            </el-date-picker>
                                        </div>
                                        <div class="flex-start" style="width: 22%;">
                                            <div
                                                class="order-reset"
                                                @click="orderreset()"
                                            >
                                                重置
                                            </div>
                                            <div
                                                class="order-badch"
                                                @click="orderquery()"
                                            >
                                                查询
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-start order-timebox" style="width:100%;">
                                        <!-- <div class="flex-start order-number tow-number" style="width:31%;">
                                            <div class="number" style="width:30%;">
                                                订单状态
                                            </div>
                                            <el-select
                                                v-model="value"
                                                placeholder="请选择"
                                             style="width: 59%;">
                                                <el-option
                                                    v-for="item in options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div> -->
                                        <div class="flex-start order-number" style="width:24%;">
                                            <div class="number">
                                                收货人
                                            </div>
                                            <el-input v-model="name" clearable @keyup.enter.native="orderquery()"></el-input>
                                        </div>
                                        <div class="flex-start order-number" style="width:28%;">
                                            <div class="number" style="width:34%">商品名称</div>
                                            <el-input
                                                v-model="goodsName"
                                                clearable
                                                @keyup.enter.native="orderquery()"
                                            ></el-input>
                                        </div>
                                        <!-- <div
                                            class="flex-start order-number"
                                         style="width:30%;">
                                            <div class="number" style="width:25%;">帐号</div>
                                            <el-select
                                                v-model="account"
                                                slot="prepend"
                                                placeholder="请选择"
                                            >
                                                <el-option
                                                    v-for="(
                                                        item, index
                                                    ) in accountList"
                                                    :key="index"
                                                    :label="item.userAccount"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </div> -->
                                    </div>
                                    <div class="flex-start order-timebox">
                                        <!-- <div class="flex-start order-number">
                                            <el-checkbox v-model="checked">全部</el-checkbox>
                                        </div> -->
                                        <div
                                            class="flex-start order-number"
                                        >
                                            <div
                                                class="order-reset"
                                                :class="{
                                                    notAllowed: exportLoading
                                                }"
                                                @click="exportOrders()"
                                            >
                                                <i v-if="exportLoading" class="el-icon-loading"></i>
                                                订单导出
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-box">
                                    <div class="order-box-top flex-start">
                                        <div style="width: 52%;" class="flex-start">
                                            <div class="goods-infor">商品信息</div>
                                            <div class="goods-price">单价</div>
                                            <div class="goods-how">数量</div>
                                        </div>
                                        <div style="width: 48%;" class="flex-start">
                                            <div class="goods-name">收货人</div>
                                            <div class="goods-start">订单状态</div>
                                            <div class="goods-sum">订单金额</div>
                                            <div class="goods-start">付款状态</div>
                                            <div class="goods-hand">操作</div>
                                        </div>
                                    </div>
                                    <div v-if="goodsList.length != 0"  v-loading="tableLoading">
                                        <div
                                            class="order-box-cent"
                                            v-for="(item, index) in goodsList"
                                            :key="index"
                                        >
                                            <div class="cent-top flex-start">
                                                <el-checkbox
                                                    @change="(checked) => handleChange(checked, item.id)"
                                                ></el-checkbox>

                                                <div class="top-time">{{ item.createTime }}</div>
                                                <div class="top-number-box">
                                                    <div v-if="item.orderNo != item.forderNo" class="top-number">
                                                        主订单编号:
                                                        <span>{{ item.forderNo }}</span>
                                                    </div>
                                                    <div class="top-number">订单编号:<span>{{ item.orderNo }}</span></div>
                                                    <div v-if="item.externalOrderNo != null" class="top-number">
                                                        第三方订单编号:
                                                        <span>{{ item.externalOrderNo }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cent-goods">
                                                <div style="width: 52%" class="flex-column-center" v-if="item.orderGoodsList.length > 0">
                                                    <div
                                                        style="width: 100%;height: 100%;"
                                                        class="flex-start"
                                                        v-for="(itm, indx) in item.orderGoodsList"
                                                        :key="indx"
                                                    >
                                                        <div class="goods-infor flex-start">
                                                            <div class="infor-img flex-center">
                                                                <img :src="itm.goodsPic" alt="" />
                                                            </div>
                                                            <div class="infor-name flex-column-center">
                                                                <div>
                                                                    <span class="title_name" v-show="itm.sourceType == 0">自营</span>
                                                                    <span class="title_name" v-show="itm.sourceType == 1">京东</span>
                                                                    <span class="title_name" v-show="itm.sourceType == 2">震坤行</span>
                                                                    <span class="title_name" v-show="itm.sourceType == 3">怡亚通</span>
                                                                    {{ itm.goodsName }}
                                                                </div>
                                                                <div>
                                                                    {{ itm.specValue }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="goods-prict flex-center">
                                                            <div>￥{{ itm.goodsPrice }}</div>
                                                        </div>
                                                        <div class="goods-number goods-prict flex-center">
                                                            <div>{{ itm.number }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-center" style="width: 52%;font-size: 13px;" v-else>
                                                    无商品数据
                                                </div>

                                                <div style="display: flex; width: 48%">
                                                    <div style="display: flex; width: 100%">
                                                        <div class="goods-name">
                                                            <div style="padding-top: 10px">{{ item.customerName }}</div>
                                                        </div>
                                                        <div class="goods-star">
                                                            <div style="padding-top: 10px">
                                                            {{ stateName(item.auditState, item.state) }}
                                                            </div>
                                                        </div>
                                                        <div class="goods-sum">
                                                            <div style="padding-top: 10px">￥{{ item.totalAmount }}</div>
                                                        </div>
                                                        <div class="goods-star">
                                                            <div style="padding-top: 10px">
                                                            {{ item.payTime ? '已付款' : '待付款' }}
                                                            </div>
                                                        </div>
                                                        <!-- 采购+审批 -->
                                                        <div class="goods-hand" v-if="authorityJudgment([3]) && authorityJudgment([2, 4])">
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('审批',item.auditState, item.state) && item.canAudit == true"
                                                                @click="auditOrders(item)"
                                                            >
                                                                审批
                                                            </div>
                                                            <div class="goods-hand-item" 
                                                                @click="lookApproveFileBtn(item)"
                                                            >
                                                                查看审批文件
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('上传汇款信息', item.auditState, item.state) && item.payTime == null"
                                                                @click="isSubmit((mergeId = item.mergeId), item.id)"
                                                            >
                                                                上传汇款信息
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('对公转账信息', item.auditState, item.state) && item.payTime == null"
                                                                @click="transferInfo = true"
                                                            >
                                                                对公转账信息
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('查看订单', item.auditState, item.state)"
                                                                @click="orderDetails(item)" 
                                                            >
                                                                查看订单
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="
                                                                    operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) == '待发货' && 
                                                                    item.cannotCancel != true && 
                                                                    item.orderGoodsList.length != 0 && 
                                                                    item.orderGoodsList[0].sourceType != 3"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="
                                                                    operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) == '待付款' && 
                                                                    item.orderGoodsList.length != 0 && 
                                                                    item.orderGoodsList[0].sourceType != 3"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) == 'jump'"
                                                                @click="unorder(item, 1)"
                                                            >
                                                                申请售后
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) && item.cannotCancel != true"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) && item.auditState >= 20"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('确认收货', item.auditState, item.state) && item.orderAssociationType != 4"
                                                                @click="affirmorder(item.id)"
                                                            >
                                                                确认收货
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('重新购买', item.auditState, item.state)"
                                                                @click="buyAgainBtn(item)"
                                                            >
                                                                重新购买
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('重新加入购物车', item.auditState, item.state)"
                                                                @click="addToCartAgain(item)"
                                                            >
                                                                重新加入购物车
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                @click="checkinvoice(item.invoiceId)"
                                                                v-if="operationBtn('查看发票', item.auditState, item.state)"
                                                            >
                                                                查看发票
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                @click="applyActivate(item.sign)"
                                                                v-if="item.sign && userId == item.memberId"
                                                            >
                                                                申请激活
                                                            </div>
                                                            <!-- <div class="goods-hand-item"
                                                                @click="deleteOrder(item.id)"
                                                            >
                                                                删除订单
                                                            </div> -->
                                                        </div>
                                                        <!-- 采购 -->
                                                        <div class="goods-hand" v-else-if="authorityJudgment([3])"> 
                                                            <div class="goods-hand-item" 
                                                                @click="lookApproveFileBtn(item)"
                                                            >
                                                                查看审批文件
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('上传汇款信息', item.auditState, item.state) && item.payTime == null"
                                                                @click="isSubmit((mergeId = item.mergeId), item.id)"
                                                            >
                                                                上传汇款信息
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('对公转账信息', item.auditState, item.state) && item.payTime == null"
                                                                @click="transferInfo = true"
                                                            >
                                                                对公转账信息
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('查看订单', item.auditState, item.state)"
                                                                @click="orderDetails(item)" 
                                                            >
                                                                查看订单
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="
                                                                    operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) == '待发货' && 
                                                                    item.cannotCancel != true && 
                                                                    item.orderGoodsList.length != 0 && 
                                                                    item.orderGoodsList[0].sourceType != 3"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="
                                                                    operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) == '待付款' && 
                                                                    item.orderGoodsList.length != 0 && 
                                                                    item.orderGoodsList[0].sourceType != 3"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) == 'jump'"
                                                                @click="unorder(item, 1)"
                                                            >
                                                                申请售后
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) && item.cannotCancel != true"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime, item.memberId) && item.auditState >= 20"
                                                                @click="unorder(item)"
                                                            >
                                                                {{aftersaleBtnName(item.state, item.payTime, item.auditState)}}
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('确认收货', item.auditState, item.state) && item.orderAssociationType != 4"
                                                                @click="affirmorder(item.id)"
                                                            >
                                                                确认收货
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('重新购买', item.auditState, item.state)"
                                                                @click="buyAgainBtn(item)"
                                                            >
                                                                重新购买
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                v-if="operationBtn('重新加入购物车', item.auditState, item.state)"
                                                                @click="addToCartAgain(item)"
                                                            >
                                                                重新加入购物车
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                @click="checkinvoice(item.invoiceId)"
                                                                v-if="operationBtn('查看发票', item.auditState, item.state)"
                                                            >
                                                                查看发票
                                                            </div>
                                                            <div class="goods-hand-item"
                                                                @click="applyActivate(item.sign)"
                                                                v-if="item.sign && userId == item.memberId"
                                                            >
                                                                申请激活
                                                            </div>
                                                        </div>
                                                        <!-- 管理 -->
                                                        <div class="goods-hand" v-else-if="!authorityJudgment([2,3,4]) && authorityJudgment(1, 5)">
                                                            <div class="goods-hand-item" @click="lookApproveFileBtn(item)">
                                                                查看审批文件
                                                            </div>
                                                            <div>
                                                                <div @click="orderDetails(item)" class="goods-hand-item">
                                                                    查看订单
                                                                </div>
                                                                <div
                                                                    v-show="item.state == 5"
                                                                    @click="checkinvoice(item.invoiceId)"
                                                                    class="goods-hand-item"
                                                                >
                                                                    查看发票
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- 审批 -->
                                                        <div class="goods-hand" v-else-if="authorityJudgment([2, 4])">
                                                            <div class="goods-hand-item" @click="lookApproveFileBtn(item)">
                                                                查看审批文件
                                                            </div>
                                                            <div>
                                                                <div
                                                                    v-if="item.canAudit == true"
                                                                    @click="auditOrders(item)"
                                                                    class="goods-hand-item"
                                                                >
                                                                    审批
                                                                </div>
                                                                <div @click="orderDetails(item)" class="goods-hand-item">
                                                                    查看订单
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="goodsList.length == 0" style="height: 500px" v-loading="tableLoading">
                                        <div style="height: 500px; line-height: 500px">
                                            <div class="flex-center" v-show="!tableLoading">暂无订单</div>
                                        </div>
                                    </div>
                                    <!-- <div class="order-box-cent">
                                        <div class="cent-top flex-start">
                                            <div class="father-time">2021-08-31 09:08:36</div>
                                            <div class="father-number">父订单编号: <span>987654321</span> </div>
                                            <div>总金额￥1200.00</div>
                                        </div>
                                        <div  class="son-top flex-start">
                                            <el-checkbox v-model="checked"></el-checkbox>
                                            <div class="top-time">2021-08-31 09:08:36</div>
                                            <div class="top-number">订单编号: <span>987654321</span> </div>
                                        </div>
                                        <div class="cent-goods flex-start">
                                            <div class="goods-infor flex-start">
                                                <div class="infor-img">
                                                    <img src="@/assets/image/001.jpg" alt="">
                                                </div>
                                                <div class="infor-name">
                                                    木方科技 2015年春装新款 预售 长袖衬衫 男衬衫    
                                                        颜色：深蓝色047   尺码：180/96A（XL码）                     
                                                </div>
                                            </div>
                                            <div class="goods-prict">
                                                <div>￥299.00</div>
                                            </div>
                                            <div class="goods-number goods-prict">
                                                <div>1</div>
                                            </div>
                                            <div class="goods-name goods-prict">
                                                <div>林黛玉</div>
                                            </div>
                                            <div class="goods-start goods-prict">
                                                <div>待审核</div>
                                            </div>
                                            <div class="goods-sum goods-prict">
                                                <div>
                                                    ￥475.00
                                                </div>
                                                
                                            </div>
                                            <div class="goods-hand ">
                                                <div @click="orderDetails()">查看订单</div>
                                                <div>取消订单</div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div
                                class="myorder-yema"
                                v-show="goodsList.length != 0"
                            >
                                <el-row
                                    type="flex"
                                    class="row-bg"
                                    justify="space-between"
                                >
                                    <el-col
                                        :span="12"
                                        style="text-align: right"
                                    >
                                        <el-pagination
                                            background
                                            @size-change="handleSizeChange"
                                            @current-change="
                                                handleCurrentChange
                                            "
                                            :current-page.sync="currentPage"
                                            :page-size="15"
                                            layout="prev, pager, next, jumper"
                                            :total="total"
                                        >
                                        </el-pagination>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="3" style="width: 15.5%"></el-col>
        </el-row>
        <Footer />
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span>是否审核通过？</span>
            <el-input v-model="remark" style="margin-top: 10px"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="ddsh(0)">审核驳回</el-button>
                <el-button type="primary" @click="ddsh(3)">审核通过</el-button>
                <el-button @click="dialogVisible = false">取消</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="对公转账信息"
            :visible.sync="transferInfo"
            width="30%"
            center
        >
            <div class="transferInfo">
                <div class="item">
                    <p class="title">户 名</p>
                    <p class="content">厦门禹佳科技有限公司</p>
                </div>
                <div class="item">
                    <p class="title">账 号</p>
                    <p class="content">35101564901052522123</p>
                </div>
                <div class="item">
                    <p class="title">开户行</p>
                    <p class="content" >中国建设银行股份有限公司厦门城市建设支行</p>
                </div>
                <!-- <div class="item">
                    <p class="title">联行号</p>
                    <p class="content">300050060066（非必填)</p>
                </div> -->
                <!-- <div class="item">
                    <p class="title">汇付识别码</p>
                    <p class="content">YZ12374568525</p>
                </div> -->
                <div class="item">
                    <p class="title">注意事项</p>
                    <p class="content">
                        汇款时将订单编号填写至汇款单“用途”,“附言”等栏。
                    </p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button
                    style="
                        background-color: #cd9f49;
                        color: #ffffff;
                        width: 80%;
                        margin-top: 50px;
                    "
                    @click="transferInfo = false"
                >
                    确 定
                </el-button>
            </span>
        </el-dialog>

        <el-dialog
            class="applicationForm"
            title="认款申请单"
            :visible.sync="applicationFormVisible"
            width="55%"
            center
            :destroy-on-close="true"
        >
            <h3>1.填写汇款信息</h3>
            <el-form
                :model="applicationForm"
                :inline="true"
                :rules="applicationFormRules"
                ref="applicationForm"
            >
                <el-form-item label="汇款公司" prop="remittanceCompany">
                    <el-input
                        v-model="applicationForm.remittanceCompany"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item
                    label="交易流水号"
                    label-width="100px"
                    prop="serialNumber"
                >
                    <el-input
                        v-model="applicationForm.serialNumber"
                        autocomplete="off"
                    ></el-input>
                </el-form-item> -->
                <el-form-item label="银行账户" prop="bankAccount">
                    <el-input
                        v-model="applicationForm.bankAccount"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item
                    label="凭证号"
                    label-width="100px"
                    prop="voucherNo"
                >
                    <el-input
                        v-model="applicationForm.voucherNo"
                        autocomplete="off"
                    ></el-input>
                </el-form-item> -->
                <el-form-item label="开户银行" prop="bankName">
                    <el-input
                        v-model="applicationForm.bankName"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="汇款时间" label-width="100px" prop="remittanceTime">
                    <el-date-picker
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间"
                        :picker-options="pickerBeginDateBefore"
                        v-model="applicationForm.remittanceTime"
                    >
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item
                    label="汇款金额"
                    prop="remittanceAmount"
                    class="number"
                >
                    <el-input-number
                        v-model="applicationForm.remittanceAmount"
                        :min="0"
                        :max="999999999"
                        label="描述文字"
                    ></el-input-number>
                </el-form-item>
            </el-form>

            <h3>2.上传汇款凭证</h3>
            <p>
                注：最多可上传3张，支持的图片格式为jpg，jpeg，png，gif，bmp，pdf。务必上传清晰图片，单个图片需≤10M。
            </p>
            <div class="flex" style="margin: 15px 0">
                <span>银行电子回执单：</span>
                <span
                    v-if="applicationForm.fileLogList.length == 0"
                    style="margin: 0 15px"
                    >未上传</span
                >
                <span v-else style="margin: 0 15px">已上传</span>
            </div>

            <!-- <el-upload
                class="upload-demo"
                action="/api/orders/order/subscription-application-form/uploadImaage"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-remove="beforeRemove"
                :headers="headers"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="applicationForm.remittanceVoucherArr"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
            >
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload> -->
            <el-upload
                v-if="!disabled"
                class="approveUpload-file"
                ref="remittanceVoucherUp"
                action=""
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :multiple="true"
                :auto-upload="true"
                :show-file-list="false"
                :before-upload="beforeVoucherFile"
                :http-request="requestVoucherFile"
            >
                    <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button v-else size="small" type="primary" :disabled="disabled">点击上传</el-button>
            <div class="dialogVisibleVoucherBox">
                <div class="VoucherFileList" v-if="remittanceVoucher.length > 0">
                    <div class="fileList flex-between" v-for="(item,index) in remittanceVoucher" :key="index">
                        <div style="width: 68%;">凭证{{index+1}}：{{item.fileName}}</div>
                        <div class="fileBtn">
                            <span @click="approvePreview(item)">在线预览</span>
                            <el-popconfirm
                                v-if="!disabled"
                                title="确定删除吗？"
                                @confirm="VoucherDel(item,index)"
                            >
                                <span slot="reference">删除</span>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="VoucherFileList" v-else>
                    <el-empty description="无凭证图片" :image-size="40"></el-empty>
                </div>
            </div>

            <!-- 图片预览 -->
            <el-image
                ref="previewImg"
                style="width: 100px; height: 100px"
                fit="fill"
                v-show="false"
                :src="picturePreview"
                :preview-src-list="[picturePreview]"
            ></el-image>

            <div slot="footer" class="dialog-footer">
                <el-button @click="applicationFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveEntity('applicationForm')" :disabled="disabled"
                    >提 交</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
              :visible.sync="dialogVisibleing"
              width="20%">
              <div style="margin-bottom:5px;">
                <div style="margin-bottom:5px;">取消订单需要拨打京东客服电话</div>
                <div>上班时间：【9：00-21:00】</div>
              </div>
              <div>
                <div style="margin-bottom:5px;">电话1</div>
                <div>05922505012</div>
              </div>
              <div style="margin:15px 0;">
                <div style="margin-bottom:5px;">电话2</div>
                <div>18650006780</div>   
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleing = false">关 闭</el-button>
              </span>
        </el-dialog>

        <el-image-viewer
            v-if="showViewer"
            :on-close="closeShowViewer"
            :url-list="showViewerList"
            style="z-index: 3000"
        />

        <approveFile
            :fileList="approveFileList"
            :dialog.sync="dialogVisibleApprove"
            :delBtn="approveFileDelBtn"
            @del="approveDel"
            @before="beforeApproveFile"
            @request="requestApproveFile"
        ></approveFile>

        <el-dialog
            class="errOrderDialog"
            title="温馨提示"
            :visible.sync="errOrderDialog"
            width="30%"
            center>
            <span>您有订单未签收，请及时确认收货!</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="errOrderDialog = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import LeftHurdle from "@/components/Layout/leftHurdle.vue";
import {
    addressList,
    deleteOrder,
    exportOrders,
    auditOrders,
    affirmorder,
    unhideorder,
    saveEntity,
    editEntity,
    buyAgain,
    addToCartAgain,
    isSubmit,
    uploadApproveFile,
    delApproveFile, 
    lookApproveFile,
    updateByCard,
} from "@/api/order/order";
import {
    // applydetails, 
    purchase,
    cancelPaidOrder 
} from "@/api/order/aftersale";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import approveFile from '@/components/public/approveFile.vue'
import { authorityJudgment } from "@/utils/util"
import { auditStateFun, auditBtnFn } from "@/utils/orderAuditNode.js"
export default {
    components: { Header, Footer, LeftHurdle, ElImageViewer, approveFile },
    data() {
        return {
            exportLoading: false,
            number: "4",
            checked: '',
            listdata: [
                { value: 0, label: "全部订单" },
                { value: 11, label: "待审核" },
                { value: 12, label: "已驳回" },
                { value: 2, label: "待发货" },
                { value: 3, label: "待收货" },
                { value: 1, label: "待付款" },
                { value: 5, label: "已完成" },
                { value: 7, label: "已取消" },
            ],
            options: [
                { value: "", label: "全部订单" },
                { value: 11, label: "待审核" },
                { value: 12, label: "已驳回" },
                { value: 2, label: "待发货" },
                { value: 3, label: "待收货" },
                { value: 1, label: "待付款" },
                { value: 5, label: "已完成" },
                { value: 7, label: "已取消" },
            ],
            value: "",
            tableLoading: false,
            pageNum: 1,
            pageSize: 15,
            total: 0,
            currentPage: 1,
            act: 0,
            goodsList: [],
            state: "",
            numberNo: "",
            value1: "",
            value2: "",
            name: "",
            goodsName: "",
            orderNo: "",
            orderId: "",
            dialogVisible: false,
            transferInfo: false, //对公转账信息
            applicationFormVisible: false, //认款申请单
            remittanceVoucher: [],// 认款申请单文件列表
            showViewer: false,
            showViewerList: [],
            applicationForm: {
                bankAccount: "", //银行账户
                bankName: "", //开户银行
                orderId: 0, //	订单id
                remittanceAmount: 0, //汇款金额
                remittanceCompany: "", //汇款公司
                remittanceTime: "", //汇款时间
                // remittanceVoucher: "", //汇款凭证图片（多个用“,”隔开）(暂时无用)
                // remittanceVoucherArr: [], //汇款凭证图片数组(暂时无用)
                fileLogList: [],  //汇款凭证图片数组
                // serialNumber: "", //流水号
                // voucherNo: "", //汇款凭证号
            },
            picturePreview: "", //图片预览
            applicationFormRules: {
                bankAccount: [
                    {
                        required: true,
                        message: "请输入银行账户",
                        trigger: "blur",
                    },
                ],
                bankName: [
                    {
                        required: true,
                        message: "请输入开户银行",
                        trigger: "blur",
                    },
                ],
                remittanceCompany: [
                    {
                        required: true,
                        message: "请输入汇款公司",
                        trigger: "blur",
                    },
                ],
                remittanceAmount: [
                    {
                        required: true,
                        message: "请输入汇款金额",
                        trigger: "blur",
                    },
                ],
                remittanceTime: [
                    {
                        required: true,
                        message: "请输入汇款时间",
                        trigger: "blur",
                    },
                ],
                // serialNumber: [
                //     {
                //         required: true,
                //         message: "请输入交易流水号",
                //         trigger: "blur",
                //     },
                // ],
                // voucherNo: [
                //     {
                //         required: true,
                //         message: "请输入汇款凭证号",
                //         trigger: "blur",
                //     },
                // ],
            },
            // orderNo:'',
            // dialogVisible:false,
            invoiceId: 0, //发票id
            invoiceList: [], //发票信息
            ordernumber: "",
            remark: "",
            account: "",
            accountList: [],
            // nametop:'我的订单',
            nametop:'订单列表',
            pickerBeginDateBefore:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            disabled:false,
            disabledEdit:false,
            editVoucherId: '',
            dialogVisibleing:false,
            headers: {Authorization: localStorage.getItem('token') },
            exportIds:[],
            mergeId:'',
            approveFileList: [],//审批文件
            approveFileDelBtn: true,
            dialogVisibleApprove: false,
            dialogVisibleApproveID: '',
            errOrderDialog: false,//是否有异常未签收订单
            authorityJudgment: authorityJudgment
        };
    },
    computed: {
        userId() {
            return this.$store.state.userId
        },
        stateName() {
            return (auditState, state) => {
                return auditStateFun(auditState, state)
            }
        },
        GoodsInfor: {
          get () { return this.$store.state.common.GoodsInfor },
          set (val) { this.$store.commit('common/upgoodsInfor', val) }
        },
        MultipleSelection: {
          get () { return this.$store.state.common.MultipleSelection },
          set (val) { this.$store.commit('common/upmultipleSelection', val) }
        },
    },

    created() {
        this.setFormat();
        if (this.$route.params.searchId) {
            this.numberNo = this.$route.params.searchId
            this.orderList("", "", "", "", 1, this.numberNo, "");
        } else {
            this.orderList("", "", "", "", 1, "", "");
        }
        purchase().then((res) => {
            if (res.code == "00000") {
                this.accountList = res.data;
            }
        });
        console.log(this.$store);
    },
    mounted() {
        if (!authorityJudgment([2,3,4]) && authorityJudgment([1, 5])) {
            this.listdata = this.listdata.slice(0, 1);
            this.options = this.options.slice(0, 1);
            // this.nametop="所有订单"
        }
        if (!authorityJudgment(3) && authorityJudgment([2, 4])) {
            // this.nametop="审核订单"
            this.listdata = this.listdata.filter(
                (item) =>
                    item.label == "待审核" ||
                    item.label == "已审核" ||
                    item.label == "已驳回" ||
                    item.label == "全部订单"
            );
            this.options = this.options.filter(
                (item) =>
                    item.label == "待审核" ||
                    item.label == "已审核" ||
                    item.label == "已驳回" ||
                    item.label == "全部订单"
            );
        }
    },
    methods: {
        // 订单操作按钮
        operationBtn(btn, auditState, state, payTime){
            // let name = this.stateName(auditState, state)
            // let isUser = ''
            // if(btn == '取消订单'){
            //     isUser = this.$store.state.userId == userId
            // }
            return auditBtnFn(btn, auditState, state, payTime)
        },
        //订单审核
        auditOrders(item) {
            this.orderId = item.id;
            this.orderNo = item.orderNo;
            this.dialogVisible = true;
        },
        //订单审核
        ddsh(status) {
            // this.dialogVisible = false;
            if (status == 0) {
                if (this.remark == "") {
                    this.$message({
                        type: "error",
                        message: "请填写驳回原因",
                    });
                } else {
                    auditOrders({
                        orderNo: this.orderNo,
                        status,
                        orderId: this.orderId,
                        remark: this.remark,
                    }).then((res) => {
                        if (res.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "驳回成功！",
                            });
                            this.dialogVisible = false;
                            this.orderList(this.value1, this.name, this.value2, this.goodsName, 1, this.numberNo, this.act);
                        } else {
                            this.$message({
                                type: "error",
                                message: "审核失败！",
                            });
                            this.dialogVisible = false;
                            this.orderList(this.value1, this.name, this.value2, this.goodsName, 1, this.numberNo, this.act);
                        }
                    });
                }
            } else {
                auditOrders({
                    orderNo: this.orderNo,
                    status,
                    orderId: this.orderId,
                    remark: this.remark,
                }).then((res) => {
                    if (res.code == "00000") {
                        this.$message({
                            type: "success",
                            message: "审核成功！",
                        });
                        this.dialogVisible = false;
                        this.orderList(this.value1, this.name, this.value2, this.goodsName, 1, this.numberNo, this.act);
                    } else {
                        this.$message({
                            type: "error",
                            message: "审核失败！",
                        });
                        this.dialogVisible = false;
                        this.orderList(this.value1, this.name, this.value2, this.goodsName, 1, this.numberNo, this.act);
                    }
                });
            }
        },
        orderList(
            beginTime,
            customerName,
            endTime,
            goodsName,
            val,
            orderNo,
            status
        ) {
            this.tableLoading = true
            this.pageNum = val
            let data = {
                beginTime: beginTime,
                customerName: customerName,
                endTime: endTime,
                goodsName: goodsName,
                myPage: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                },
                orderNo: orderNo,
                status: status,
                memberId:this.account
            };
            addressList(data).then((data) => {
                this.tableLoading = false
                if (data && data.code === "00000") {
                    if (data.data.records.length == 0) {
                        this.goodsList = [];
                    } else {
                        // console.log(data.data);
                        this.goodsList = [];
                        this.total = data.data.total;
                        this.goodsList = data.data.records;
                        // this.goodsList.forEach(item=>{
                        //     item.orderGoodsList.forEach(itm=>{
                        //         if(itm.sourceType==1){
                        //             itm.goodsPic="http://img13.360buyimg.com/n1/"+itm.goodsPic
                        //         }
                        //     })
                        // })
                    }
                    this.exportIds = [] // 复选框选中清空
                }
            }).catch(() => {
                this.tableLoading = false
            })
        },
        orderDetails(item) {
            // console.log(item);
            // localStorage.setItem("detailsItem",JSON.stringify(item))
            let obj = {
                orderNo: item.orderNo,
                invoiceId: item.invoiceId,
                id: item.id
            }
            if(this.$routerWin){
                const { href } = this.$router.resolve({ 
                    name: "OrderDetails",
                    query: { item: JSON.stringify(obj) },
                });
                window.open(href,'_blank');
            } else {
                this.$router.push({
                    name: "OrderDetails",
                    query: { item: JSON.stringify(obj) },
                });
            }
        },
        //删除订单
        deleteOrder(id) {
            this.$confirm("确认删除订单", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    deleteOrder(id).then((data) => {
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.orderList("", "", "", "", 1, "", "");
                        }
                    });
                })
                .catch(() => {});
        },
        handleSizeChange(val) {
            this.orderList(this.value1, this.name, this.value2, this.goodsName,val, this.numberNo, this.act);
        },
        handleCurrentChange(val) {
            this.orderList(this.value1, this.name, this.value2, this.goodsName, val, this.numberNo, this.act);
        },
        //切换
        changeAct(id) {
            this.act = id;
            if (id == 0) {
                this.orderList(this.value1, this.name, this.value2, this.goodsName, 1, this.numberNo, "");
                this.total=1
            } else {
                this.orderList(this.value1,this.name,this.value2,this.goodsName,1,this.numberNo,id);
                this.total=1
            }
        },
        //导出订单
        exportOrders() {
            if(this.exportLoading) return;
            let data = {
                beginTime: this.value1,
                customerName: this.name,
                endTime: this.value2,
                exportIds:this.exportIds,
                goodsName: this.goodsName,
                myPage: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                },
                orderNo: this.numberNo,
                status: this.act,
            };
            this.exportLoading = true
            exportOrders(data).then((data) => {
                this.exportLoading = false
                console.log(data);
                // let blob = new Blob([data], {type: 'application/vnd.ms-excel'})
                // const link = document.createElement('a')
                // let href = window.URL.createObjectURL(blob)
                // link.href = href;

                // link.download = '导出订单.xls'; //下载后文件名
                // document.body.appendChild(link);
                // link.click(); //点击下载
                // document.body.removeChild(link); //下载完成移除元素
                // window.URL.revokeObjectURL(href); //释放掉blob对象
            }).catch(() => this.exportLoading = false)
        },
        //查询
        orderquery() {
            let data = (new Date(this.value1)).getTime()
            let data2 = (new Date(this.value2)).getTime()
            if(data>data2){
                this.$message({
                    type: "error",
                    message: "开始时间不可以超过结束时间!",
                });
            }else{
                this.goodsList=[]
                this.orderList(
                    this.value1,
                    this.name,
                    this.value2,
                    this.goodsName,
                    1,
                    this.numberNo,
                    this.act,
                );
                
            }
            
        },
        //重置
        orderreset() {
            this.numberNo = "";
            this.value1 = "";
            this.value = "";
            this.value2="",
            this.name = "";
            this.goodsName = "";
            this.account="";
            this.goodsList=[]
            this.orderList("", "", "", "", 1, "", "");
        },
        //查看发票
        checkinvoice(id) {
            let data = {
                id,
            };
            this.$router.push({
                name: "InvoiceDetails",
                query: { item: JSON.stringify(data) },
            });
            // applydetails(data).then((data) => {
            //     if (data && data.code == "00000") {
            //         localStorage.setItem(
            //             "prizeResult",
            //             JSON.stringify(data.data)
            //         );
                    
            //     }
            // });
        },
        // 取消订单按钮名称
        aftersaleBtnName(state,payTime,type){
            if(payTime){
                return state == 1 ? '取消订单' : '申请售后'
            } else {
                return state == 2 || state == 3 || state == 5 || (state == 1 && type == 7)  ? '申请售后' : '取消订单'
            }
        },
        // 取消订单/申请售后
        unorder(item, jump) {
            console.log(item.payTime,item.state,item.auditState,'-----unorder-----')
            if(item.orderGoodsList.length == 0 || !item.orderGoodsList == null) {
                console.log('无订单数据')
                return;
            }
            if(jump) {
                this.$router.push({ name: 'ChangingRefund', query: { orderNo: item.orderNo} })
                return
            }
            if(item.payTime){
                if(item.orderGoodsList[0].sourceType == 1){
                    this.dialogVisibleing = true
                } else if(item.state == 1){
                    this.cancelOrderConfirm(item.id)
                } else {
                    this.afterSaleConfirm(item.id)
                }
            } else {
                if(item.state == 2 || item.state == 3 || item.state == 5 || (item.state == 1 && item.auditState == 7)){
                    this.afterSaleConfirm(item.id)
                } else {
                    this.cancelOrderConfirm(item.id)
                }
            }
            
        },
        // 申请售后确认
        afterSaleConfirm(id) {
            this.$confirm("是否申请售后", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                cancelPaidOrder(id).then((data) => {
                    if (data.code == "00000") {
                        this.$message({
                            type: "success",
                            duration: 8000,
                            message: "请前往售后记录查看！",
                        });
                        this.orderList("", "", "", "", 1, "", "");
                    }else if(data.code == "E0001"){
                        this.$message({
                            type: "error",
                            duration:8000,
                            message: data.desc,
                        });
                    }
                });
            })
            .catch(() => {});
        },
        // 取消订单确认
        cancelOrderConfirm(id) {
            this.$confirm("是否取消订单", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                let obj = {
                    orderId: id,
                };
                unhideorder(obj).then((data) => {
                    if (data.code == "00000") {
                        this.$message({
                            type: "success",
                            message: "取消成功!",
                        });
                        this.orderList("", "", "", "", 1, "", "");
                    }else if(data.code == "E0001"){
                        this.$message({
                            type: "error",
                            duration:8000,
                            message: data.desc,
                        });
                    }
                });
            })
            .catch(() => {});
        },
        //确认收货
        affirmorder(id) {
            this.$confirm("是否确认收到货", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let data = {
                        orderId: parseInt(id),
                    };
                    affirmorder(data).then((data) => {
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "确认成功!",
                            });
                            this.orderList("", "", "", "", 1, "", "");
                        }
                    });
                })
                .catch(() => {});
        },

        setFormat() {
            Date.prototype.format = function (format) {
                var o = {
                    "M+": this.getMonth() + 1, // month
                    "d+": this.getDate(), // day
                    "h+": this.getHours(), // hour
                    "m+": this.getMinutes(), // minute
                    "s+": this.getSeconds(), // second
                    "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
                    S: this.getMilliseconds(),
                    // millisecond
                };
                if (/(y+)/.test(format)) {
                    format = format.replace(
                        RegExp.$1,
                        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                    );
                }
                for (var k in o) {
                    if (new RegExp("(" + k + ")").test(format)) {
                        format = format.replace(
                            RegExp.$1,
                            RegExp.$1.length == 1
                                ? o[k]
                                : ("00" + o[k]).substr(("" + o[k]).length)
                        );
                    }
                }
                return format;
            };
        },
        // 上传文件前
        beforeVoucherFile(file){
            // console.log(file);
            const fileSuffix2 = file.name.substring(file.name.lastIndexOf(".") + 1);
 
            const whiteList2 = ["jpg","jpeg","png","gif","bmp","pdf","JPG","JPEG","PBG","GIF","BMP","PDF"];
            const VoucherSize = 10485760;
            
            if(this.remittanceVoucher.length >= 3){
                this.$message({message:"最多上传3个文件！", type: 'warning'});
                return false;
            }
            if (whiteList2.indexOf(fileSuffix2) === -1) {
                this.$message({message:"上传格式错误！", type: 'warning'});
                return false;
            } else if(VoucherSize <= file.size){
                this.$message({message:"上传文件的大小需小于等于10MB！", type: 'warning'});
                return false;
            }
        },
        // 自定义上传
        requestVoucherFile(params){
            // console.log(params,'up3');

            let formData = new FormData()
            formData.append("file", params.file)
            formData.append("type", 2)
            // formData.append("correlationId", this.mergeId)
            
            uploadApproveFile(formData).then(res => {
                if(res.code == '00000'){
                    if(this.remittanceVoucher.length < 3) {
                        this.$message({message:"上传成功", type: 'success'});
                        this.remittanceVoucher.push(res.data)
                        this.applicationForm.fileLogList.push({
                            fileName: res.data.fileName,
                            fileUrl: res.data.fileUrl,
                            type: res.data.type,
                        })
                    } else {
                        this.$message({message:"最多上传3个文件", type: 'warning'});
                    }
                } else {
                    this.$message({message: res.desc, type: 'error'});
                }
            })
        },
        VoucherDel(item,index){
            // console.log(item);
            this.remittanceVoucher.splice(index,1)
            this.applicationForm.fileLogList.splice(index,1)
            this.$message({message:"删除成功", type: 'success'});
        },
        // 文件在线预览
        approvePreview(item){
            // console.log(item);
            this.showViewer = true
            this.showViewerList = [item.fileUrl]
        },
        closeShowViewer(){
            this.showViewer = false
        },
        //认款申请单
        saveEntity(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.applicationForm.fileLogList.length != 0) {
                        // this.applicationForm.remittanceVoucher = "";
                        // let remittanceVoucher = this.applicationForm.remittanceVoucherArr
                        //     .map((item) => item.url)
                        //     .join(",");
                        // this.applicationForm.remittanceVoucher = remittanceVoucher;
                        this.applicationForm.orderId = this.mergeId;
                        if(this.applicationForm.remittanceTime == '') {
                            this.applicationForm.remittanceTime = this.getNowFormatDay()
                        }
                        let data ={
                            "bankAccount": this.applicationForm.bankAccount,
                            "bankName": this.applicationForm.bankName,
                            "fileLogList": this.applicationForm.fileLogList,
                            "orderId": this.applicationForm.orderId,
                            "remittanceAmount": this.applicationForm.remittanceAmount,
                            "remittanceCompany": this.applicationForm.remittanceCompany,
                            "remittanceTime": this.applicationForm.remittanceTime,
                        }
                        if(!this.disabledEdit){
                            saveEntity(data).then((res) => {
                                if (res.code == "00000") {
                                    this.$message({
                                        type: "success",
                                        message: res.desc,
                                    });
                                    this.applicationFormVisible = false;
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.desc,
                                    });
                                }
                            });
                        } else {
                            console.log('editEntity');
                            data.id = this.editVoucherId
                            editEntity(data).then((res) => {
                                if (res.code == "00000") {
                                    this.$message({
                                        type: "success",
                                        message: res.desc,
                                    });
                                    this.applicationFormVisible = false;
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.desc,
                                    });
                                }
                            });
                        }
                        
                    } else {
                        this.$message({
                            type: "error",
                            message: "请上传汇款凭证！",
                        });
                        return;
                    }
                } else {
                    // console.log("error submit!!");
                    return false;
                }
            });
        },
        // 获取当前时间
        getNowFormatDay(){
            let char = "-";
            let char2 = ":";
			let nowDate = new Date();
            let year = nowDate.getFullYear();
			let month = nowDate.getMonth() + 1;//注意月份需要+1
            let day = nowDate.getDate();
            let hours = nowDate.getHours();
            let minutes = nowDate.getMinutes();
            let seconds = nowDate.getSeconds();
			//补全0，并拼接
            let time = year + char + 
            this.completeDate(month) + char + 
            this.completeDate(day) + ' ' + 
            this.completeDate(hours) + char2 + 
            this.completeDate(minutes) + char2 + 
            this.completeDate(seconds);
			return time
        },
        completeDate(value) {//补0拼接
			return value < 10 ? "0"+value:value;
		},

        //重新购买
        buyAgainBtn(item) {
            this.$confirm("是否重新购买?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在加载中···',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.4)'
                    });
                    
                    buyAgain({ orderId: item.id }).then((data) => {
                        loading.close();
                        if (data && data.code == "00000") {
                            if(data.data.length > 0){
                                let arr = data.data.map(item => ({
                                    actType: item.actType,
                                    goodsId: item.goodsId,
                                    skuId: item.skuId,
                                    nums: item.num,
                                    kind: item.kind
                                }))
                                // console.log(arr);
                                this.GoodsInfor = JSON.stringify(arr)
                                this.MultipleSelection = ''
                                this.$router.push({ name: "GoodsClose" });
                            } else {
                                this.$message.warning('获取数据为空！')
                            }
                        } else {
                            this.$message.error(data.desc ?? '操作失败！')
                        }
                    });
                })
                .catch(() => {});
        },
        // 重新购买
        goToGoodsClose(item){
            console.log(item);
            const loading = this.$loading({
                lock: true,
                text: '正在加载中···',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });

            setTimeout(() => {
                loading.close()
            }, 1000)
            
            if(item.orderGoodsList.length > 1){
                // sessionStorage.setItem('goodsInfor','')
                // sessionStorage.setItem('multipleSelection', JSON.stringify(item.orderGoodsList))
                // this.$router.push({ name: "GoodsClose" });
                let arr = item.orderGoodsList.map(item => ({
                    goodsid: item.goodsId,
                    skuid: item.productSkuStockId,
                    number: item.number,
                    kind: item.kind
                }))
                console.log(arr);
            } else if(item.orderGoodsList.length == 1) {
                console.log({
                    goodsid: item.orderGoodsList[0].goodsId,
                    skuid: item.orderGoodsList[0].productSkuStockId,
                    number: item.orderGoodsList[0].number,
                    kind: item.orderGoodsList[0].kind
                });
                // this.$router.push({
                //     name: "GoodsClose",
                //     query: {
                //         goodsid: item.orderGoodsList[0].goodsId,
                //         skuid: item.orderGoodsList[0].productSkuStockId,
                //         number: item.orderGoodsList[0].nums,
                //         kind: item.orderGoodsList[0].kind
                //     },
                // });
            }
        },
        //重新加入购物车
        addToCartAgain(item) {
            this.$confirm("是否重新加入购物车", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // console.log(item);
                    let data = {
                        orderId: item.id,
                    };
                    addToCartAgain(data).then((data) => {
                        // console.log(data);
                        if (data && data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "添加成功！",
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: data.desc,
                            });
                        }
                    });
                })
                .catch(() => {});
        },
        //查看是否上传凭证
        isSubmit(mergeId, id){
            this.applicationFormVisible=true
            let isUpload = this.goodsList.find(item => item.id == id)
            // console.log(isUpload,'isUpload');
            if(!isUpload.payTime){
                this.disabled=false
            } else {
                this.disabled=true
            }
            let data={
                "orderId": mergeId
            }
            isSubmit(data).then((data)=>{
                // console.log(data);
                if(data.code==='00000'){
                    if(data.data!=null){
                        this.disabledEdit=true
                        this.editVoucherId=data.data.id
                        this.applicationForm.bankAccount=data.data.bankAccount
                        this.applicationForm.bankName=data.data.bankName
                        this.applicationForm.remittanceAmount=data.data.amount
                        this.applicationForm.remittanceCompany=data.data.remittanceCompany
                        this.applicationForm.remittanceTime=data.data.remittanceTime
                        this.applicationForm.serialNumber=data.data.serialNumber
                        this.applicationForm.voucherNo=data.data.voucherNo
                        // if(data.data.remittanceVoucher!=''){
                        //     if (data.data.remittanceVoucher) {
                        //         let arr = data.data.remittanceVoucher.split(",");
                        //         // console.log(arr, "arr");
                        //         // console.log(this.applicationForm.remittanceVoucherArr, "this.form");
                        //         if(arr.length==0){
                        //             this.applicationForm.remittanceVoucherArr =[]
                        //         }else{
                        //             this.applicationForm.remittanceVoucherArr =[]
                        //             arr.forEach((item)=>{
                        //                 if(item!=''){
                        //                     let temp = {
                        //                         name:item,
                        //                         url: item,
                        //                     };
                        //                     this.applicationForm.remittanceVoucherArr.push(temp);
                        //                 }
                        //             })
                        //         }
                        //     }
                        // }
                    }else{
                        this.disabledEdit=false
                        this.applicationForm.bankAccount=''
                        this.applicationForm.bankName=''
                        this.applicationForm.remittanceAmount=''
                        this.applicationForm.remittanceCompany=''
                        this.applicationForm.remittanceTime=''
                        // this.applicationForm.serialNumber=''
                        // this.applicationForm.voucherNo=''
                        // this.applicationForm.remittanceVoucher=""
                        // this.applicationForm.remittanceVoucherArr=[]
                    }
                }
            })
            // 查看凭证
            lookApproveFile({
                id: this.mergeId,
                type: 2
            }).then(res => {
                // console.log(res);
                if(res.code == '00000'){
                    this.remittanceVoucher = res.data.filter(item => item.type == 2)
                    this.applicationForm.fileLogList = res.data.filter(item => item.type == 2 && delete item.id)
                }
            })

        },
        handleChange(checked,id){
            // console.log(checked,id)
            if(checked==true){
                this.exportIds.push(id)
            }else{
                this.exportIds.forEach((item,index)=>{
                    if(id==item){
                        this.exportIds.splice(index,1)
                    }
                })
            }
        },

        // 查看审批文件按钮
        lookApproveFileBtn(item){
            // console.log(item);
            this.dialogVisibleApprove = true
            this.dialogVisibleApproveID = item.id
            if(item.auditState > 1){
                this.approveFileDelBtn=false
            } else {
                this.approveFileDelBtn=true
            }
            lookApproveFile({
                id: item.id,
                type: 1
            }).then(res => {
                // console.log(res);
                if(res.code == '00000'){
                    this.approveFileList = res.data.filter(item => item.type == 1)
                }
            })
            
        },
        // 审批文件删除
        approveDel(item,index){
            // console.log(item);
            const loading = this.$loading({
                lock: true,
                text: '正在删除中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            delApproveFile(item.id).then(res => {
                loading.close();
                if(res.code == '00000'){
                    this.$message({message:"删除成功", type: 'success'});
                    this.approveFileList.splice(index,1)
                } else {
                    this.$message({message:"删除失败", type: 'error'});
                }
            })
        },
        // 审批文件上传
        // 上传文件前
        beforeApproveFile(file){
            // console.log(file);
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
 
            const whiteList = ["jpg", "png", "pdf", "JPG", "PNG", "PDF"];
            const size = 10485760;

            if(this.approveFileList.length >= 10){
                this.$message({message:"最多上传10个文件", type: 'error'});
                return false;
            }
            // let aName = this.approveFileList.find(item => item.fileName == file.name)
            // if(aName){
            //     this.$message({message:"重复上传文件名！", type: 'warning'});
            //     return false;
            // }
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message({message:"上传文件只能是 jpg,png,pdf格式", type: 'warning'});
                return false;
            } else if(size <= file.size){
                this.$message({message:"上传文件的大小需小于等于10MB", type: 'warning'});
                return false;
            }
        },
        // 自定义上传
        requestApproveFile(params){
            // console.log(this.upApproveFileList,'up');
            // console.log(params,'up2');

            let formData = new FormData()
            formData.append("file", params.file)
            formData.append("type", 1)
            formData.append("correlationId", this.dialogVisibleApproveID)
            
            uploadApproveFile(formData).then(res => {
                
                if(res.code == '00000'){
                    this.$message({message:"上传成功", type: 'success'});
                    this.approveFileList.push(res.data)
                } else {
                    this.$message({message:"上传失败", type: 'error'});
                }
            })
        },
        // 申请激活
        applyActivate(id) {
            if(!id) return;
            this.$confirm("是否确认申请激活", '提示', {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在处理中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    console.log(id);
                    updateByCard({
                        id,
                        status: 1,
                    }).then((data) => {
                        // console.log(data);
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: data.desc || "操作成功！",
                            });
                            this.orderList(this.value1, this.name, this.value2, this.goodsName, this.pageNum, this.numberNo, this.act);
                        } else {
                            this.$message({
                                type: "error",
                                message: data.desc,
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        loading.close()
                    })
                })
                .catch(() => {});
        }
        
    },
};
</script>

<style lang="scss">
.transferInfo {
    .item {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 20%;
            flex-shrink: 0;
            margin-right: 5%;
        }
        
    }
}
.settings_box {
    margin: 30px 0;
    .el-breadcrumb {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .box_left {
        margin-right: 20px;
    }
    .box_rights {
        width: 87%;
        border: 1px solid #d7d7d7;
        border-radius: 10px;
        .box_right_top {
            height: 40px;
            line-height: 40px;
            padding-left: 40px;
            background: #d7d7d7;
            border-bottom: 1px solid #d7d7d7;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-weight: bold;
        }
        .order_top {
            padding: 20px 30px;
            .order-cut {
                margin-right: 30px;
                .order-name {
                    position: relative;

                    &:hover {
                        cursor: pointer;
                        color: #cd9f49;
                    }
                }
                .order-number {
                    position: absolute;
                    top: -7px;
                    right: -34px;
                    color: red;
                    font-size: 16px;
                }
            }
            .order-text {
                color: #cd9f49;
            }
            .order-texts {
                color: #333;
            }

            .order-line {
                border: 0.5px solid #cd9f49;
            }
            .order-number {
                // margin-right: 25px;
                .number {
                    width: 30%;
                }
                .numbers {
                    width: 30%;
                }
                .order-start {
                    width: 78px;
                }
            }
            .tow-number{
                .el-input--suffix{
                    width: 97%;
                }
            }
            .order-timebox {
                margin: 20px 0;
                .time-input {
                    width: 30%;
                }
            }
            .el-input {
                width: 60%;
            }
            .orderTime {
                margin: 0 16px;
            }
            .zhi {
                margin: 0 10px;
            }
        }
        .order-badch {
            width: 80px;
            text-align: center;
            height: 28px;
            line-height: 28px;
            color: #fff;
            background: #cd9f49;
            border-radius: 5px;
            font-size: 14px;

            &:hover {
                cursor: pointer;
                box-shadow: 0 0 4px 0 #cd9f49;
            }
        }
        .order-reset {
            width: 80px;
            text-align: center;
            height: 28px;
            line-height: 28px;
            border: 1px solid #333;
            border-radius: 5px;
            font-size: 14px;
            margin: 0 20px;

            &:hover {
                cursor: pointer;
                color: #cd9f49;
                border-color: #cd9f49;
            }
        }
        .order-box {
            width: 100%;
            .order-box-top {
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 15px;
                background: #e1e1e1;
                text-align: center;
                .goods-infor {
                    width: 76%;
                    border-right: 1px solid #fff;
                }
                .goods-price {
                    width: 12%;
                    border-right: 1px solid #fff;
                }
                .goods-how {
                    width: 12%;
                    border-right: 1px solid #fff;
                }
                .goods-name {
                    width: 19%;
                    border-right: 1px solid #fff;
                }
                .goods-start {
                    width: 24%;
                    border-right: 1px solid #fff;
                }
                .goods-sum {
                    width: 24%;
                    border-right: 1px solid #fff;
                }
                .goods-hand {
                    width: 31%;
                }
            }
            .order-box-cent {
                width: 100%;
                margin: 20px 0;
                .cent-top {
                    min-height: 28px;
                    line-height: 28px;
                    font-size: 15px;
                    background: #cccccc;
                    padding: 0 20px;
                    .father-number {
                        margin: 0 20px;
                    }
                    .top-time {
                        margin: 0 16px;
                        width: 150px;
                        flex-shrink: 0;
                    }
                    .top-number-box {
                        display: flex;
                        flex-wrap: wrap;
                        width: 545px;
                        .top-number {
                            margin-right: 14px;
                        }
                    }
                    span {
                        color: #cd9f49;
                    }
                }
                .son-top {
                    height: 28px;
                    line-height: 28px;
                    font-size: 15px;
                    background: #e1e1e1;
                    padding: 0 20px;
                    margin-top: 10px;
                    .top-time {
                        margin: 0 20px;
                    }
                    span {
                        color: #cd9f49;
                    }
                }
                .cent-goods {
                    width: 100%;
                    // height: 119px;
                    display: flex;
                    flex: 0 1 auto;
                    border-bottom: 1px solid #d7d7d7;
                    .goods-infor {
                        width: 76%;
                        height: 100%;
                        min-height: 163px;
                        border-right: 1px solid #d7d7d7;
                        border-top: 1px solid #d7d7d7;
                        .infor-img {
                            width: 47%;
                            height: 100%;
                            border-right: 1px solid #d7d7d7;
                            // padding: 42px 0;
                            text-align: center;
                            img {
                                width: 74px;
                                height: 74px;
                            }
                        }
                        .infor-name {
                            width: 44%;
                            font-size: 15px;
                            padding: 0 20px;
                            .title_name {
                                color: #fff;
                                background: #cd9f49;
                                padding: 0 5px;
                            }
                        }
                    }
                    .goods-prict {
                        width: 12%;
                        height: 100%;
                        border-right: 1px solid #d7d7d7;
                        border-top: 1px solid #d7d7d7;
                        // height: 163px;
                        font-size: 12px;
                        text-align: center;
                        // line-height: 163px;
                    }
                    .goods-number {
                        width: 12%;
                    }
                    .goods-name {
                        width: 19%;
                        height: 100%;
                        border-right: 1px solid #d7d7d7;
                        font-size: 12px;
                        text-align: center;
                        border-bottom: 1px solid #d7d7d7;
                    }
                    .goods-star {
                        width: 24%;
                        height: 100%;
                        border-right: 1px solid #d7d7d7;
                        font-size: 12px;
                        text-align: center;
                        border-bottom: 1px solid #d7d7d7;
                    }
                    .goods-sum {
                        width: 24%;
                        height: 100%;
                        border-right: 1px solid #d7d7d7;
                        font-size: 16px;
                        text-align: center;
                        border-bottom: 1px solid #d7d7d7;
                    }
                    .goods-hand {
                        width: 31%;
                        height: 100%;
                        border-right: none;
                        text-align: center;
                        font-size: 14px;
                        border-bottom: 1px solid #d7d7d7;
                    }
                    .goods-hand-item {
                        padding-top: 8px;

                        &:hover {
                            cursor: pointer;
                            color: #cd9f49;
                        }
                    }
                }
            }
        }
    }
}
.myorder-yema {
    margin: 10px 0;
    .el-pager li.active {
        color: #cd9f49;
    }
    .el-row--flex.is-justify-space-between {
        justify-content: center;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #cd9f49;
    }
}
.dialogVisibleVoucherBox {
    width: 100%;
    min-height: 150px;
    height: auto;
    position: relative;

    .VoucherFileList {
        width: 100%;
        height: auto;
        margin: 10px 0 0;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width : 7px;
            height: 1px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            background   : #808080;
        }
        &::-webkit-scrollbar-track {
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            background   : #ededed;
        }

        .fileList {
            margin-bottom: 15px;

            .fileBtn {
                width: 30%;
                color: #CD9F49;

                span {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
    
}
.approveUpload-file {
    display: inline-block;
    margin-right: 30px;
}
.errOrderDialog {
    .el-dialog {
        .el-dialog__body {
            text-align: center !important;
        }
    }
}
</style>